
import { getArticles } from '@/apis/articles'
import { ArticleModel } from '@/model/articleModel'
import { defineComponent, onMounted, reactive, toRefs } from 'vue'

import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const dataMap = reactive({
      list: Array<ArticleModel>(), // 表格数据
      v: {}, // 是否可编辑
      listLoading: true, // 是否需要加载动画
      listQuery: { // 请求示例参数
        page: 1,
        limit: 10
      },
      async getList() {
        dataMap.listLoading = true
        const data = await getArticles(dataMap.listQuery)
        const items = data?.data.items
        if (items) {
          dataMap.list = items.map((v: any) => {
            dataMap.v = { edit: false }
            v.originalTitle = v.title // will be used when user click the cancel botton
            return v
          })
        }
        // Just to simulate the time of the request
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      cancelEdit(row: any) {
        row.title = row.originalTitle
        row.edit = false

        ElMessage.success({
          message: 'The title has been restored to the original value',
          type: 'success'
        })
      },
      confirmEdit(row: any) {
        row.edit = false
        row.originalTitle = row.title
        ElMessage.success({
          message: 'The title has been edited',
          type: 'success'
        })
      }
    })

    onMounted(() => {
      dataMap.getList()
    })

    return { ...toRefs(dataMap) }
  }

})
